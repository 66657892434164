// NavBar.js
import React from 'react';
import { Link } from 'react-router-dom';

function NavBar() {
    return (
        <nav class="navbar navbar-inverse navbar-default navbar-fixed-top">
            <div class="container">
                <div class="navbar-header">
                    <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar">
                        <span class="sr-only">Toggle navigation</span>
                        <span class="icon-bar"></span>
                        <span class="icon-bar"></span>
                        <span class="icon-bar"></span>
                    </button>
                    <a class="navbar-brand" href="#" >AI Use Cases</a>
                </div>
                <div id="navbar" class="collapse navbar-collapse">
                    <ul class="nav navbar-nav">
                        <li class="active" ><Link to="/">Home</Link></li>
                        {/*<li class="active" ><Link to="/stats">Stats</Link></li>*/}
                        {/*<li class="active"><a href="#">Use Case Feasibility</a></li>*/}
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default NavBar;
