import React from 'react';
import { Routes, Route } from 'react-router-dom';

import NavBar from './NavBar';
import HomePage from './HomePage';
//import MapComponent from './MapComponent';

//... your component definition
function MyComponent() {
    return (
        <>
            <NavBar />
            <br />
            <Routes>
                <Route path="/" element={<HomePage />} />
                {/*<Route path="/stats" element={<MapComponent />} />*/}
                {/* Add more routes as necessary */}
            </Routes>
        </>
    );
}
export default MyComponent;
