import React, { useState } from 'react';
import { Tooltip } from 'react-tooltip'
import { emailGreetingTranslations } from './translations';
import { postFixTranslations } from './translations';


const HomePage = () => {

    const [link, setLink] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [hasErrorOccured, setHasErrorOccured] = useState(false);
    const [profilingFailed, setProfilingFailed] = useState(false)
    const [companyProfile, setData] = useState({
        CompanyName: '',
        CompanyHeadQuarter: '',
        CompanyProfile: '',
        ProductsAndServices: '',
        StrategicGoals: '',
        ChallengesAndPainPoints: '',
        Leadership: '',
        EmployeeInfo: '',
        PerformanceMeasures: '',
        SustainabilityPractices: '',
        InnovationPractices: '',
        CompetitiveLandscape: '',
        TechnologicalCapabilities: '',
        CustomerBase: '',
        CustomerNeeds: ''
    });


    const [hasCompanyProfile, setHasCompanyProfile] = useState(false);
    const [useCases, setUseCases] = useState('');
    const [isUseCaseLoading, setIsUseCaseLoading] = useState(false);

    const [emailGreeting, setEmailGreeting] = useState(emailGreetingTranslations.English);
    const [postFix, setPostFix] = useState(postFixTranslations.English);
    const [language, setLanguage] = useState('English');

    const handleLanguageChange = (event) => {
        const lang = event.target.value;
        setLanguage(lang);
        setEmailGreeting(emailGreetingTranslations[lang]);
        setPostFix(postFixTranslations[lang]);
    };

    const validateURL = (url) => {
        try {
            new URL(url);
            return true;
        } catch (err) {
            return false;
        }
    };

    const handleButtonClick = async () => {
        try {
            if (!validateURL(link)) {
                alert('Please enter a valid URL');
                return;
            }

            //https%3A%2F%2Fwww.kenanga.com.my%2F
            setHasErrorOccured(false);
            setProfilingFailed(false);
            setIsLoading(true);
            setHasCompanyProfile(false);
            var url = encodeURIComponent(link);

            //clear the compay profile
            setData({
                CompanyName: '',
                CompanyHeadQuarter: '',
                CompanyProfile: '',
                ProductsAndServices: '',
                StrategicGoals: '',
                ChallengesAndPainPoints: '',
                Leadership: '',
                EmployeeInfo: '',
                PerformanceMeasures: '',
                SustainabilityPractices: '',
                InnovationPractices: '',
                CompetitiveLandscape: '',
                TechnologicalCapabilities: '',
                CustomerBase: '',
                CustomerNeeds: ''
            });


            //clear the use cases
            setUseCases({
                GenerativeAIUseCases: '[Generative AI Use Cases]',
                AIUseCases: '[AI Use Cases]'
            });

            //const response = await fetch('${ process.env.REACT_APP_API_BASE_URL}/AzureOpenAI?link=${ encodeURIComponent(link)}');
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/AzureOpenAI?link=${encodeURIComponent(link)}`);

            const data = await response.text();
            const companyProfile = JSON.parse(data);
            setIsLoading(false);

            //cleaning the response
            const cleanedData = {};
            for (let key in companyProfile) {
                if (typeof companyProfile[key] === 'string') {
                    const trimmedData = companyProfile[key].trim();
                    cleanedData[key] = trimmedData.endsWith('1') ? trimmedData.slice(0, -1) : trimmedData;
                } else {
                    cleanedData[key] = companyProfile[key];
                }
            }

            // Assuming setData is the setter function from a useState hook
            setData(cleanedData);

            setHasCompanyProfile(true);
        } catch (error) {
            console.error("Error fetching data: ", error);
            setHasErrorOccured(true);
            setProfilingFailed(true);
            setIsLoading(false);
            setHasCompanyProfile(false);
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleButtonClick();
        }
    };

    const handleShowMeUseCaseButtonClick = async () => {
        try {

            setIsUseCaseLoading(true);
            setHasErrorOccured(false);

            const requestBody = {
                dataPoints: JSON.stringify(companyProfile),
                link: encodeURIComponent(link),
                language: language // this should be the language selected by the user
            };

            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/AzureOpenAI`, {
                method: 'POST',
                headers: {
                    'accept': 'text/plain',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.text();
            //const useCases = JSON.parse(data);  // Convert response string to JSON object

            setIsUseCaseLoading(false);
            if (!data || data.trim() === '') {
                setHasErrorOccured(true);
            }
            setUseCases(data);
        } catch (error) {
            console.error("Error fetching data: ", error);
            setIsUseCaseLoading(false);
            setHasErrorOccured(true);
        }
    };

    function convertToHtml(text) {
        if (typeof text !== 'string') {
            console.error('convertToHtml was called with a non-string argument:', text);
            return '';
        }
        const lines = text.split('\n');

        return lines.map(line => {
            const splitLine = line.split(':');
            if (splitLine.length > 1) {
                // This line has a heading - make it bold
                return `<b>${splitLine[0]}:</b>${splitLine.slice(1).join(':')}`;
            } else {
                // This line does not have a heading - leave it as it is
                return line;
            }
        }).join('<br />');
    }


    const copyToClipboard = async () => {
        try {

            const textToCopy = JSON.stringify(useCases);
            const cleanedText = textToCopy.replace(/\\r\\n/g, ' ');
            await navigator.clipboard.writeText(cleanedText);

            // Notify the user that the copy was successful
            //alert('Copied to clipboard');
        } catch (err) {
            // Notify the user that the copy failed
            alert('Failed to copy text');
        }
    };


    // insert your home page JSX here
    return (
        <div className="container mt-4">
            <Tooltip className="tooltipIndex" id="tooltip-btnExtractProfile" />
            <Tooltip className="tooltipIndex" id="tooltip-btnGenerateUseCases" />
            <Tooltip className="tooltipIndex"  id="tooltip-profile" />
            <Tooltip className="tooltipIndex"  id="tooltip-ddLanguage" />
            <Tooltip className="tooltipIndex"  id="tooltip-useCases" />

            <div>
                { /* alert */}
                {hasErrorOccured && (
                    <div className="alert alert-danger" role="alert">
                        An Error occured. Please try again.
                        {profilingFailed && (
                            <div>
                                <strong> Sometimes link to a sub-page works!</strong> For example: https://teeg.com/our-story
                            </div>
                        )}
                    </div>
                )}

                {/*Top Row Search Bar and Button*/}
                <div className="row">
                    <div className="col-md-8 text-left">
                        <input tabIndex="1" type="text" className="form-control"
                            value={link}
                            onChange={e => setLink(e.target.value)}
                            onKeyDown={handleKeyDown} // Listen for keydown events
                            placeholder="Enter Company URL here" />
                    </div>
                    <div className="col-md-4 text-left">
                        <button tabIndex="2" id="btnExtractProfile"
                            data-tooltip-id="tooltip-btnExtractProfile" data-tooltip-content="Extracts the Profile from the Web-Url"
                            className="btn btn-primary mt-2" 
                            disabled={isLoading} onClick={handleButtonClick} >Extract Profile</button>
                    </div>

                </div>

                {/*Progress Bar*/}
                <div>
                    {isLoading && (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div className="loader"></div>
                            <span style={{ marginLeft: '4px' }}>Extracting Profile...</span>
                        </div>
                    )}
                </div>

                <div className="row" style={{ margin: '10px 10px 0px 0px' }}>
                    <label htmlFor="profileLabel"
                        data-tooltip-id="tooltip-profile" data-tooltip-content="Shows Complete Profile of the Company"
                    >Profile:</label>
                </div>
                
                {/*Result Section*/}

                <div className="row" id="mainContent" tabIndex="4">
                    {/*Compay Profile*/}
                    <div className="col-md-6" id="companyProfile"
                        style={{ maxHeight: 'calc(100vh - 200px)', width: '50%', overflowY: 'auto' }}>
                        {/* The form group is repeated for each label */}
                      

                        <div className="form-group" style={{ margin: '0px 10px 0px 0px' }}>
                            {companyProfile.CompanyName && (
                                <div tabIndex="3" style={{ display: 'flex', alignItems: 'center' }}>
                                    <label htmlFor="companyName">Name:</label>
                                    <h5 style={{ margin: '0 5px 5px 5px' }}>{companyProfile.CompanyName}</h5>
                                    <label style={{ margin: '0 5px 5px 5px' }} htmlFor="headquarter">Headquarter:</label>
                                    <h5 style={{ margin: '0 5px 5px 5px' }}>{companyProfile.CompanyHeadQuarter}</h5>
                                </div>
                            )}
                        </div>

                        <div className="form-group">
                            <label htmlFor="companyProfile">1.Business Overview</label>
                            <p>{companyProfile.CompanyProfile}</p>
                            <textarea className="form-control hidden" id="companyProfile" rows={3} defaultValue={""} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="productsServices">2.Products And Services</label>
                            <p>{companyProfile.ProductsAndServices}</p>
                            <textarea className="form-control hidden" id="productsServices" rows={3} defaultValue={""} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="strategicGoals">3.Strategic Goals</label>
                            <p>{companyProfile.StrategicGoals}</p>
                            <textarea className="form-control hidden" id="strategicGoals" rows={3} defaultValue={""} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="challengesAndPainPoints">4.Challenges And Pain Points</label>
                            <p>{companyProfile.ChallengesAndPainPoints}</p>
                            <textarea className="form-control hidden" id="challengesAndPainPoints" rows={3} defaultValue={""} />
                        </div>

                        <div className="form-group">
                            <label htmlFor="leadership">5.Leadership</label>
                            <p>{companyProfile.Leadership}</p>
                            <textarea className="form-control hidden" id="leadership" rows={3} defaultValue={""} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="employeeInfo">6.Employee Info</label>
                            <p>{companyProfile.EmployeeInfo}</p>
                            <textarea className="form-control hidden" id="employeeInfo" rows={3} defaultValue={""} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="performanceMeasures">7.Performance Measures</label>
                            <p>{companyProfile.PerformanceMeasure}</p>
                            <textarea className="form-control hidden" id="performanceMeasures" rows={3} defaultValue={""} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="sustainabilityPractices">8.Sustainability Practices</label>
                            <p>{companyProfile.SustainabilityPractices}</p>
                            <textarea className="form-control hidden" id="sustainabilityPractices" rows={3} defaultValue={""} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="innovationPractices">9.Innovation Practices</label>
                            <p>{companyProfile.InnovationPractices}</p>
                            <textarea className="form-control hidden" id="innovationPractices" rows={3} defaultValue={""} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="competitiveLandscape">10.Competitive Landscape</label>
                            <p>{companyProfile.CompetitiveLandscape}</p>
                            <textarea className="form-control hidden" id="competitiveLandscape" rows={3} defaultValue={""} />
                        </div>

                        <div className="form-group">
                            <label htmlFor="technologicalCapabilities">11.Technological Capabilities</label>
                            <p>{companyProfile.TechnologicalCapabilities}</p>
                            <textarea className="form-control hidden" id="technologicalCapabilities" rows={3} defaultValue={""} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="customerBase">12.Customer Base</label>
                            <p>{companyProfile.CustomerBase}</p>
                            <textarea className="form-control hidden" id="customerBase" rows={3} defaultValue={""} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="customerNeeds">13.Customer Needs</label>
                            <p>{companyProfile.CustomerNeeds}</p>
                            <textarea className="form-control hidden" id="customerNeeds" rows={3} defaultValue={""} />
                        </div>
                    </div>
                    {/*Use Cases*/}
                    <div className="col-md-6" id="useCases">
                        <div className="row">
                            <div className="col-md-4 text-left"
                                data-tooltip-id="tooltip-ddLanguage" data-tooltip-content="Select the preferred language. If you change the language then generate use cases again.">
                                <select className="form-control" onChange={handleLanguageChange} tabIndex="5">
                                    <option selected>English</option>
                                    <option>Korean</option>
                                    <option>Chinese</option>
                                    <option>Japanese</option>
                                </select>
                            </div>
                            <div className="col-md-4 text-left" tabIndex="6">
                                <button className="btn btn-primary mt-2"
                                    disabled={!hasCompanyProfile || isUseCaseLoading}
                                    data-tooltip-id="tooltip-btnGenerateUseCases"
                                    data-tooltip-content="Generates the Use Cases"
                                    onClick={handleShowMeUseCaseButtonClick}>Generate Use Cases</button>
                            </div>
                            <div>
                                {isUseCaseLoading && (
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div className="loader"></div>
                                        <span style={{ marginLeft: '4px' }}>Generating Use Cases...</span>
                                    </div>
                                )}
                            </div>
                        </div>
                        <br />
                        <div className="row form-group">
                            <div className="col-md-4 text-left" tabIndex="7">
                                <label htmlFor="useCases"
                                    data-tooltip-id="tooltip-ddLanguage"
                                    data-tooltip-content="Shows the outreach Email and Use Cases"
                                >Use Cases: </label>
                            </div>
                            {/*<div className="col-md-4 text-right">*/}
                            {/*    <button className="btn btn-outline-secondary ml-2" id="copyBtn" onClick={copyToClipboard}>Copy to Clipboard</button>*/}
                            {/*</div>*/}
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                {/*<textarea style={{ height: '500px', width: '650px' }}*/}
                                {/*    className="form-control mt-2"*/}
                                {/*    id="useCases"*/}
                                {/*    rows={3}*/}
                                {/*    value={emailGreeting + useCases + "\n" + postFix}*/}
                                {/*/>*/}
                                <div className="col-md-4" tabIndex="8">
                                    <div style={{ height: '500px', width: '550px', border: '1px solid', overflow: 'auto' }}
                                        className="form-control mt-2"
                                        id="useCases"
                                        contentEditable="true"
                                        dangerouslySetInnerHTML={{ __html: emailGreeting + convertToHtml(useCases) + "<br />" + postFix }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomePage;
