export const emailGreetingTranslations = {
    English: `Dear [Customer], <br /><br />
    I hope this email finds you well. Today, I'm excited to introduce you to the possibilities of AI with Azure OpenAI Service, an advanced language model from Microsoft that will revolutionize the way you do business.
Azure OpenAI Service is a powerful tool that lets you leverage large-scale, generative AI models with deep understandings of language and code. With it, you can enable new reasoning and comprehension capabilities for building cutting-edge applications​​. This means you can power your apps with AI models capable of writing assistance, code generation, and reasoning over data, among other things.
Here are a few ways you can integrate Azure OpenAI Service into your operations.`,
    Korean: `고객님, 안녕하세요], <br></br> 이 이메일이 여러분께 잘 전달되길 바랍니다. 오늘은 마이크로소프트의 고급 언어 모델인 Azure OpenAI Service를 통해 AI의 가능성을 소개해드리고자 합니다. 이 서비스는 비지니스 방식을 혁신시킬 것입니다.
Azure OpenAI Service는 언어와 코드에 대한 깊은 이해를 바탕으로 대규모 생성 AI 모델을 활용할 수 있는 강력한 도구입니다. 이를 통해 최첨단 애플리케이션을 구축하기 위한 새로운 추론 및 이해 능력을 활성화할 수 있습니다. 이는 쓰기 지원, 코드 생성 및 데이터에 대한 추론 등 AI 모델을 통해 앱을 구동할 수 있음을 의미합니다.
아래는 Azure OpenAI Service를 비즈니스에 통합하는 몇 가지 방법입니다.`,
    Chinese: `亲爱的[客户]，<br></br>
希望您一切都好。今天，我很高兴向您介绍Azure OpenAI Service的AI可能性，这是来自微软的先进语言模型，将彻底改变您的商业方式。
Azure OpenAI Service是一种强大的工具，可让您利用具有深入理解语言和代码的大规模生成AI模型。使用它，您可以为构建尖端应用程序启用新的推理和理解能力。这意味着您可以用AI模型为您的应用程序提供写作协助、代码生成和数据推理等功能。
以下是您可以将Azure OpenAI Service集成到您的操作中的几种方法。`,
    Japanese: `[お客様]各位 <br></br>
このメールがお届けされることを願っています。本日は、Microsoftの高度な言語モデルであるAzure OpenAI Serviceを使用したAIの可能性をご紹介いたします。このサービスは、ビジネスのやり方を革新する強力なツールです。
Azure OpenAI Serviceは、言語とコードの深い理解を基盤とした大規模な生成AIモデルを活用することができます。これにより、最先端のアプリケーションを構築するための新しい推論および理解能力を活性化することができます。これは、AIモデルを活用して、ライティング支援、コード生成、データに対する推論など、さまざまなことを実現することができます。
以下は、Azure OpenAI Serviceをビジネスに統合する方法のいくつかです。`
};

export const postFixTranslations = {
    English: `</br>In addition to its versatility, Azure OpenAI Service prioritizes responsible AI use. It allows you to filter and moderate the content of users' requests and responses to ensure the AI models are used responsibly and for their intended purpose. Moreover, Azure OpenAI Service is built on Azure's global infrastructure, ensuring critical enterprise security, compliance, and regional availability for your mission-critical workloads.
<br /><br />For added flexibility, Azure OpenAI Service follows a pay-as-you-go consumption model, allowing you to only pay for what you use.
Companies across various sectors are already reaping the benefits of Azure OpenAI Service. For instance, CarMax used GPT-3 AI language models to generate summarized content from customer reviews, enhancing the shopping experience for their customers. Kepro, a leading provider of medical management and health improvement solutions, has implemented OpenAI to improve medical record review accuracy and reduce clinical review time by up to 30 percent.
<br /><br />Take this opportunity to join the AI revolution with Azure OpenAI Service. We're here to support you every step of the way as you transform your operations and enhance the value you deliver to your clients.`,
    Korean: `</br>Azure OpenAI Service는 다양한 용도로 사용 가능한 것 외에도, 책임 있는 AI 사용을 우선적으로 고려합니다. 이를 통해 AI 모델이 책임 있게 사용되고 의도된 목적으로 사용되도록 사용자의 요청과 응답의 내용을 필터링하고 조절할 수 있습니다. 또한, Azure OpenAI Service는 Azure의 글로벌 인프라에 구축되어 중요한 기업 보안, 규정 준수 및 지역 가용성을 보장합니다.
<br /><br />더욱 유연성을 높이기 위해 Azure OpenAI Service는 사용량에 따라 지불하는 요금제를 따르므로 필요한 만큼만 지불하면 됩니다.
다양한 산업 분야의 기업들은 이미 Azure OpenAI Service의 혜택을 누리고 있습니다. 예를 들어, CarMax는 GPT-3 AI 언어 모델을 사용하여 고객 리뷰에서 요약된 콘텐츠를 생성하여 고객의 쇼핑 경험을 향상시켰습니다. 의료 관리 및 건강 개선 솔루션의 주요 제공 업체인 Kepro는 OpenAI를 구현하여 의료 기록 검토 정확도를 개선하고 임상 검토 시간을 최대 30% 줄일 수 있었습니다.
<br /><br />Azure OpenAI Service를 통해 AI 혁명에 참여할 기회를 잡으세요. 우리는 당신이 운영을 변화시키고 고객에게 제공하는 가치를 향상시키는 모든 단계에서 지원할 준비가 되어 있습니다.`,
    Chinese: `</br>除了其多功能性外，Azure OpenAI Service 还优先考虑负责任的 AI 使用。它允许您过滤和调节用户请求和响应的内容，以确保 AI 模型负责任地用于其预定用途。此外，Azure OpenAI Service 建立在 Azure 的全球基础设施上，确保您的关键企业安全、合规性和地区可用性，以应对关键工作负载。
<br /><br />为了增加灵活性，Azure OpenAI Service 遵循按使用量付费的消费模型，让您只需支付您使用的费用。
各个行业的公司已经开始受益于 Azure OpenAI Service。例如，CarMax 使用 GPT-3 AI 语言模型从客户评论中生成摘要内容，提高了客户的购物体验。Kepro 是一家领先的医疗管理和健康改善解决方案提供商，已经实施 OpenAI，以提高医疗记录审查准确性并将临床审查时间缩短最多 30%。
<br /><br />利用 Azure OpenAI Service 加入 AI 革命的机会。我们将支持您在转变业务并增强向客户提供的价值的每一步中`,
    Japanese: `</br>Azure OpenAI Serviceは、その汎用性に加え、責任あるAIの利用を優先しています。ユーザーのリクエストやレスポンスの内容をフィルタリングしてモデレートすることで、AIモデルが意図された目的で責任を持って使用されることを保証しています。さらに、Azure OpenAI Serviceは、Azureのグローバルインフラストラクチャ上に構築されており、ミッションクリティカルなワークロードに対して、重要な企業セキュリティ、コンプライアンス、およびリージョナルアベイラビリティを確保しています。
<br /><br />さらなる柔軟性のために、Azure OpenAI Serviceは従量制の消費モデルに従っており、必要に応じて支払いが可能です。
既に多くの業界で、Azure OpenAI Serviceの利点を享受しています。例えば、CarMaxは、GPT-3 AI言語モデルを使用して、顧客レビューから要約されたコンテンツを生成し、顧客のショッピング体験を向上させました。医療管理や健康改善ソリューションを提供する業界のリーダーであるKeproは、OpenAIを導入して、医療記録の精度を向上させ、臨床レビュー時間を最大30％削減しました。
<br /><br />Azure OpenAI Serviceと共にAI革命に参加するこの機会をお見逃しなく。私たちは、お客様がオペレーションを変革し、顧客に提供する価値を向上させるために、一歩一歩サポートしていきます。`,
};